.coin-row {
	align-items: center;
	min-height: 80px;
	border-bottom: 1px solid #d7d7d7;
	text-align: center;
}

.coin {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.coin img {
	height: 30px;
	width: 30px;
	margin-right: 10px;
}

.coin h1 {
	font-size: 16px;
}

.coin-symbol {
	text-transform: uppercase;
}

.red {
	color: #f00606;
}

.green {
	color: #11d811;
}

h1,
p {
	margin-bottom: 0;
}

@media only screen and (max-width: 768px) {
	.coin-row {
		padding: 20px 0;
	}
}
